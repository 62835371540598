import { z } from 'zod'

import { getLocalStorageValue } from './localStorage'

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, unknown>[]
}

type GTMItemType = {
  [key: string]: string | number | undefined | null
}

type GTMEventType = {
  [key: string]: string | number | undefined | null | { items: GTMItemType[] }
}

type Variation = {
  id: number
  name: string
}

type ABTastyTestEntry = {
  id: number
  name: string
  variations?: Record<string, Variation | undefined>
}

type WindowWithABTasty = Window & {
  ABTasty?: {
    getCampaignHistory: () => Record<string, string> // campaignId -> variationId (as string)
    accountData?: {
      tests?: Record<string, ABTastyTestEntry>
    }
  }
}

declare const window: WindowWithDataLayer & WindowWithABTasty

export const GTM_ID = process.env.NEXT_PUBLIC_GTM

export const sendGTMEvent = (data: GTMEventType) => {
  if (window.dataLayer !== undefined) {
    const customerId = getLocalStorageValue('customerId', z.string(), '')

    if (customerId) {
      window.dataLayer.push({
        ...data,
        customer_id: customerId,
      })
    } else {
      window.dataLayer.push(data)
    }
  }
}

export const contractItemsEvent = (
  event: string,
  ecommerceData: GTMEventType,
  data: GTMItemType[],
) => {
  sendGTMEvent({ ecommerce: null })

  const { abTastyCampaings, abTastyVariations } = getABTastyCampaignsAndVariations()

  const gtmData = {
    event,
    ecommerce: {
      ...ecommerceData,
      abtasty_campaign: abTastyCampaings,
      abtasty_variation: abTastyVariations,
      items: data,
    },
  }
  sendGTMEvent(gtmData)
}

export const stepEvent = (event: string, data: GTMItemType) => {
  sendGTMEvent({ ecommerce: null })
  sendGTMEvent({
    event,
    ...data,
  })
}

export const getABTastyCampaignsAndVariations = () => {
  if (
    window !== undefined &&
    window.ABTasty &&
    typeof window.ABTasty.getCampaignHistory === 'function'
  ) {
    const campaignHistory = window.ABTasty.getCampaignHistory()
    const tests = window.ABTasty.accountData?.tests || {}

    const abTastyCampaings = Object.keys(campaignHistory)

    const abTastyVariations = abTastyCampaings
      .map((campaignId) => {
        const variationId = campaignHistory[campaignId]
        const test = tests[campaignId]
        return test?.variations?.[variationId]?.name ?? (variationId == '0' ? variationId : null)
      })
      .filter(Boolean)

    return {
      abTastyCampaings,
      abTastyVariations,
    }
  }

  return {
    abTastyCampaings: [],
    abTastyVariations: [],
  }
}
